const TYPES = {
    UPDATE_LOGIN_USER : 'UPDATE_LOGIN_USER',
    UPDATE_USER : 'UPDATE_USER',
    UPDATE_WAREHOUSES : 'UPDATE_WAREHOUSES',
    UPDATE_WEBSHOP : 'UPDATE_WEBSHOP',
    UPDATE_SINGLE_WEBSHOP : 'UPDATE_SINGLE_WEBSHOP',
    UPDATE_WEBSHOP_ORDERS : 'UPDATE_WEBSHOP_ORDERS',
    UPDATE_WEBSHOP_PRODUCTS : 'UPDATE_WEBSHOP_PRODUCTS',
    UPDATE_SUPPLIER_ORDERS : 'UPDATE_SUPPLIER_ORDERS',
    UPDATE_SUPPLIERS : 'UPDATE_SUPPLIERS',
    UPDATE_PRICELIST : 'UPDATE_PRICELIST',
    SEARCH_RESULTS : 'SEARCH_RESULTS',
    UPDATE_USER_PHOTO : 'UPDATE_USER_PHOTO',
    UPDATE_WEBSHOP_PRODUCTS_SEARCH : 'UPDATE_WEBSHOP_PRODUCTS_SEARCH',
    UPDATE_WEBSHOP_ORDER_STATUS : 'UPDATE_WEBSHOP_ORDER_STATUS',
    UPDATE_SUPPLIER_ORDER_STATUS : 'UPDATE_SUPPLIER_ORDER_STATUS',
    UPDATE_LINKED_USERS : 'UPDATE_LINKED_USERS',

    UPDATE_POPULAR_ITEMS : 'UPDATE_POPULAR_ITEMS'
}

export default TYPES