<template>
    <div class="absolute inset-0 z-50 backdrop" v-if="mobile" @click="removeSidebar"></div>
    <div :class="sidebarOpen ? 'w-64 transition-width' : 'hidden w-64'" class="flex h-screen md:flex flex-col absolute z-50 md:z-0 md:static bg-sidebar-gray text-white p-5 transition-width">
        <div class="flex mb-2">
            <div class="h-10 w-1/2">
                <span class="flex font-semibold" >
                    <img src="./../../../assets/jit-logo.svg" alt="">
                </span>
            </div>
        </div>
        <div>
            <div>
                <ul>
                    <li>
                        <a href="javascript:void(0);" class="accordion is_open block whitespace-nowrap overflow-hidden px-3">
                            <div class="flex items-center">
                                <span><img class="h-3 w-3" src="./../../../assets/images/sidebar/dashboard.png" alt=""></span>
                                <span class="text-base ml-3 text-gray-900 font-semibold">{{ $t('dashboard') }}</span>
                            </div>
                        </a>
                        <ul class="accordion-content" style="max-height: 152px;">
                            <li class="py-1">
                                <router-link :to="{ name : 'dashboard'}" class="block whitespace-nowrap overflow-hidden px-3 py-2">
                                    <div class="flex items-center">
                                        <!-- <span><img src="./../../../assets/images/Dashboard/dash-icon.png" alt=""></span> -->
                                        <span class="text-sm ml-3 text-theme-gray font-semibold">{{ $t('report') }}</span>
                                    </div>
                                </router-link>
                                <router-link :to="{ name : 'webshop'}" class="block whitespace-nowrap overflow-hidden px-3 py-2">
                                    <div class="flex items-center">
                                        <!-- <span><img src="./../../../assets/images/Dashboard/dash-icon.png" alt=""></span> -->
                                        <span class="text-sm ml-3 text-theme-gray font-semibold">{{ $t('webshop') }}</span>
                                    </div>
                                </router-link>
                                <router-link :to="{ name : 'warehouse'}" class="block whitespace-nowrap overflow-hidden px-3 py-2">
                                    <div class="flex items-center">
                                        <!-- <span><img src="./../../../assets/images/Dashboard/dash-icon.png" alt=""></span> -->
                                        <span class="text-sm ml-3 text-theme-gray font-semibold">{{ $t('warehouse') }}</span>
                                    </div>
                                </router-link>
                                <router-link :to="{ name : 'suppliers'}" class="block whitespace-nowrap overflow-hidden px-3 py-2">
                                    <div class="flex items-center">
                                        <!-- <span><img src="./../../../assets/images/Dashboard/dash-icon.png" alt=""></span> -->
                                        <span class="text-sm ml-3 text-theme-gray font-semibold">{{ $t('supplier') }}</span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="accordion is_open block whitespace-nowrap overflow-hidden px-3 py-2">
                            <div class="flex items-center">
                                <span><img class="h-3 w-3" src="./../../../assets/images/sidebar/product.png" alt=""></span>
                                <span class="text-base ml-3 text-gray-900 font-semibold">{{ $t('product') }}</span>
                            </div>
                        </a>
                        <ul class="accordion-content" style="max-height: 96px;">
                            <li class="py-1">
                                <router-link :to="{ name : 'price-rule'}" class="block whitespace-nowrap overflow-hidden px-3 py-2">
                                    <div class="flex items-center">
                                        <!-- <span><img src="./../../../assets/images/Dashboard/dash-icon.png" alt=""></span> -->
                                        <span class="text-sm ml-3 text-theme-gray font-semibold">{{ $t('priceRule') }}</span>
                                    </div>
                                </router-link>
                                <router-link :to="{ name : 'price-management'}" class="block whitespace-nowrap overflow-hidden px-3 py-2">
                                    <div class="flex items-center">
                                        <!-- <span><img src="./../../../assets/images/Dashboard/dash-icon.png" alt=""></span> -->
                                        <span class="text-sm ml-3 text-theme-gray font-semibold">{{ $t('productManager') }}</span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="accordion is_open block whitespace-nowrap overflow-hidden px-3 py-2">
                            <div class="flex items-center">
                                <span><img class="h-3 w-3" src="./../../../assets/images/sidebar/orders.png" alt=""></span>
                                <span class="text-base ml-3 text-gray-900 font-semibold">{{ $t('order') }}</span>
                            </div>
                        </a>
                        <ul class="accordion-content" style="max-height: 96px;">
                            <li class="py-1">
                                <router-link :to="{ name : 'webshop-orders'}" class="block whitespace-nowrap overflow-hidden px-3 py-2">
                                    <div class="flex items-center">
                                        <!-- <span><img src="./../../../assets/images/Dashboard/dash-icon.png" alt=""></span> -->
                                        <span class="text-sm ml-3 text-theme-gray font-semibold">{{ $t('webshopOrderManager') }}</span>
                                    </div>
                                </router-link>
                                <router-link :to="{ name : 'supplier-orders'}" class="block whitespace-nowrap overflow-hidden px-3 py-2">
                                    <div class="flex items-center">
                                        <!-- <span><img src="./../../../assets/images/Dashboard/dash-icon.png" alt=""></span> -->
                                        <span class="text-sm ml-3 text-theme-gray font-semibold">{{ $t('supplierOrderManager') }}</span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="accordion is_open block whitespace-nowrap overflow-hidden px-3 py-2">
                            <div class="flex items-center">
                                <span><img class="h-3 w-3" src="./../../../assets/images/sidebar/settings.png" alt=""></span>
                                <span class="text-base ml-3 text-gray-900 font-semibold">{{ $t('setting') }}</span>
                            </div>
                        </a>
                        <ul class="accordion-content" style="max-height: 96px;">
                            <li class="py-1">
                                <router-link :to="{ name : 'accounts'}" class="block whitespace-nowrap overflow-hidden px-3 py-2">
                                    <div class="flex items-center">
                                        <!-- <span><img src="./../../../assets/images/Dashboard/dash-icon.png" alt=""></span> -->
                                        <span class="text-sm ml-3 text-theme-gray font-semibold">{{ $t('account') }}</span>
                                    </div>
                                </router-link>
                                <router-link :to="{ name : 'users'}" class="block whitespace-nowrap overflow-hidden px-3 py-2">
                                    <div class="flex items-center">
                                        <!-- <span><img src="./../../../assets/images/Dashboard/dash-icon.png" alt=""></span> -->
                                        <span class="text-sm ml-3 text-theme-gray font-semibold">{{ $t('users') }}</span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    
                </ul>
            </div>
        </div>
        <!-- <div>
            <div>
                <ul>
                    <li class="py-1">
                        <a href="javascript:void(0);" @click="logoutUser" class="block whitespace-nowrap overflow-hidden px-3 py-2">
                            <div class="flex items-center">
                                <span><img src="./../../../assets/images/Dashboard/logout.png" alt=""></span>
                                <span class="text-sm ml-3 text-gray-400">Logout</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div> -->
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Bus from './../../../bus'


    export default {
        name : 'Sidebar',
        data () {
            return {
                mobile : false,
                serverURL : process.env.VUE_APP_BASE_URL,
                sidebarOpen : false
            }
        },
        computed : {
            ...mapGetters({
                REFRESH_TOKEN : 'auth/REFRESH_TOKEN',
                GET_ORGANIZATION_INFO : 'jitcontrol/GET_ORGANIZATION_INFO'
            })
        },
        mounted () {
            const connectSupplier = window.localStorage.getItem('__supplier__')
            // if (connectSupplier) {
            //     this.getEmployeeOrganizationInfo(connectSupplier)
            // } else {
            //     this.getOrganizationInfo()
            // }

            // Bus.on('toggle-sidebar', () => {
            //     this.mobile = !this.mobile
            //     this.sidebarOpen = !this.sidebarOpen
            // })
            this.accordion()
        },
        methods : {
            accordion () {
                const accordionBtns = document.querySelectorAll(".accordion");
                if (accordionBtns) {
                    accordionBtns.forEach((accordion) => {
                        accordion.onclick = function () {
                            this.classList.toggle('is_open');
    
                            let content = this.nextElementSibling;
                            if (content && content.tagName === 'UL') {
                                if (this.classList.contains('is_open')) {
                                    content.classList.toggle('hidden')
                                } else {
                                    content.classList.toggle('hidden')
                                }
                            }
    
                            if (content.style.maxHeight) {
                                //this is if the accordion is open
                                content.style.maxHeight = null;
                            } else {
                                //if the accordion is currently closed
                                content.style.maxHeight = content.scrollHeight + "px";
                                console.log(content.style.maxHeight);
                            }
                        }
                    })
                }

            },
            removeSidebar () {
                this.sidebarOpen = false
                this.mobile = false
            }
        }
    }
</script>

<style lang='scss' scoped>
.sidebar-bg{
    background: #08204E;
}
ul > li > a:hover {
    // background: #36F8AE;
    border-radius: 0.3rem;
    span {
        // color: #08204E !important;
    }
}
li .router-link-exact-active {
    background: #DDE6F2;
    // border-radius: 0.3rem;
    border-left-color: #07387A;
    border-left-width: 2px;
    span {
        font-weight: 800 !important;
        color: #07387A !important;
        // color: #08204E !important;
    }
}
// .router-exact-color {
//     // background: #36F8AE;
// }
// .brand-name {
//     // color: #36F8AE;
// }
.backdrop {
    opacity: .5;
    background: #000;
}
.transition-width {
    transition: width 2s;
}
.accordion {
    width: 100%;
    /* background-color: whitesmoke;
    border: none;
    outline: none;
    text-align: left;
    padding: 15px 20px;
    font-size: 18px;
    color: #333; */
    cursor: pointer;
    transition: background-color 0.2s linear;
}

// .accordion.is-open {
//     // background-color: #ddd;
// }
// .accordion:after {
//     font-family: FontAwesome;
//     content: "\f150";
//     font-family: "fontawesome";
//     font-size: 18px;
//     float: right;
// }
// .accordion.is-open:after {
//     content: "\f151";
// }
.accordion-content {
    // background-color: white;
    /* border-left: 4px solid #BF377B; */
    padding: 0 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
  }
</style>