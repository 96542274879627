import { createStore } from 'vuex'
import auth from './module/auth'
import jitcontrol from './module/jitcontrol'
import locale from './module/locale'

const store = createStore({
    modules : {
        auth,
        jitcontrol,
        locale
    }
})

export default store
