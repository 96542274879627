export default {
    GET_LOGIN_USER (state) {
        return state.loginUser
    },
    GET_CHOOSEN_COMPANY (state) {
        return state.choosenCompany
    },
    GET_USER_INFORMATION (state) {
        return state.userInfo
    },
    GET_WAREHOUSES (state) {
        return state.warehouses
    },
    GET_WEBSHOPS (state) {
        return state.webshop
    },
    GET_SINGLE_WEBSHOP (state) {
        return state.singleWebshop
    },
    GET_WEBSHOP_ORDERS (state) {
        return state.webshopOrders
    },
    GET_LINKED_USERS (state) {
        return state.linkedUsers
    },
    GET_WEBSHOP_PRODUCTS (state) {
        return state.webshopProducts
    },
    GET_WEBSHOP_PRODUCT_CHECKED (state) {
        if (state.webshopProducts.results) {
            const filterChecked = state.webshopProducts.results.filter(item => item.checked === true)
            if (filterChecked.length > 0) {
                return { status : true, data : filterChecked }
            } else {
                return { status : false, data : [] }
            }
        }
    },
    GET_SUPPLIER_ORDERS (state) {
        return state.supplierOrders
    },
    GET_SUPPLIERS (state) {
        return state.suppliers
    },
    GET_PRICE_LIST (state) {
        return state.priceList
    },
    GET_PRICE_RULE (state) {
        return state.priceRule
    },
    GET_POPULAR_ITEMS (state) {
        return state.popularItems
    }
}
