export default {
    USER_TOKEN (state) {
        return state.token
    },
    USER_REFRESH (state) {
        return state.refresh
    },
    GET_USER_REF (state) {
        return state.userRef
    },
}
