export const CalculateSalesPrice = (cost, margin, defaultVat = 25) => {
    const costPrice = cost
    const grossMargin = 1 - parseFloat(margin / 100)

    const salesPriceExVat = costPrice / grossMargin
    const salesPriceIncVat = salesPriceExVat * parseFloat(1 + (defaultVat / 100))
    return { salesPriceExVat, salesPriceIncVat }
}

export const CalculateSalesAndMargin = (cost, priceExVat, defaultVat = 25) => {
    const priceIncVat = parseFloat(priceExVat * parseFloat(1 + (defaultVat / 100))).toFixed()

    const grossProfit = priceExVat - cost // Get gross profit

    const profitMargin = grossProfit / priceExVat

    const marginPercentage = parseFloat(profitMargin * 100).toFixed(2)
    return { priceIncVat, marginPercentage }
}

export const profitMargin = (cost, salesPriceExVat) => {
    const grossProfit = salesPriceExVat - cost // Get gross profit

    const profitMargin = grossProfit / salesPriceExVat
    return parseFloat(profitMargin * 100).toFixed(4) // Expressing it as percentage
}

export const CalculateGrossMargin = (cost, sales, defaultVat = 25) => {
    const costPrice = cost
    const salesPriceIncVat = sales
    const salesPriceExVat = salesPriceIncVat / (1 + parseFloat(defaultVat / 100)) // Get salesprice ex vat
    
    const grossProfit = salesPriceExVat - costPrice // Get gross profit

    const profitMargin = grossProfit / salesPriceExVat
    const marginPercentage = parseFloat(profitMargin * 100).toFixed(4)

    return { salesPriceExVat, marginPercentage }
}

export default { CalculateSalesPrice, CalculateGrossMargin, CalculateSalesAndMargin }
