<template>
    <div class="absolute bg-red-00 inset-0 z-10" v-if="openSettings || openCompany || openSwitchTranslation" @click="removeMenu"></div>
    <header class="bg-white shadow-md">
        <div class="px-3">
            <div class="flex justify-between items-center h-16">
                <div class="flex">
                    <button @click="toggleSidebar" class="sm:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
                <div class="flex space-x-4 items-center">
                    <div>
                        <span v-if="GET_LOCALE === 'en'" @click="openSwitchTranslation = true" class="cursor-pointer"><img class="h-6 w-6" src="./../../../assets/images/flags/4x3/GB.svg" alt=""></span>
                        <span v-else @click="openSwitchTranslation = true" class="cursor-pointer"><img class="h-6 w-6" src="./../../../assets/images/flags/4x3/SE.svg" alt=""></span>
                        <div v-if="openSwitchTranslation" class="absolute bg-white rounded mt-9 w-40 z-20 -ml-20 border shadow-lg top-6 mr-0">
                            <div class="text-xs text-gray-500 uppercase pb-2 mt-4 px-3">{{ $t('languages') }}</div>
                            <ul>
                                <li class="border-b border-t border-gray-100">
                                    <a href="#" class="block px-4" @click="changeLocale('se')">
                                        <span class="flex items-center space-x-3 block text-sm mb-2">
                                            <span class="text-gray-800 text-xs"><img class="h-6 w-6" src="./../../../assets/images/flags/4x3/SE.svg" alt=""></span>
                                            <span class="text-gray-800 text-xs">Swedish</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="border-b border-gray-100">
                                    <a href="#" class="block px-4" @click="changeLocale('en')">
                                        <span class="flex items-center space-x-3 block text-sm mb-2">
                                            <span class="text-gray-800 text-xs"><img class="h-6 w-6" src="./../../../assets/images/flags/4x3/GB.svg" alt=""></span>
                                            <span class="text-gray-800 text-xs">English</span>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                            <!-- <div @click="openDrawer = true" class="text-center bg-gray-100 p-2 text-sm">View More</div> -->
                        </div>
                    </div>
                    <!-- <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                        </svg>
                    </div> -->
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                        </svg>
                    </div>
                    <div>
                        <div v-if="loading" class="flex items-center space-x-2 cursor-pointer">
                            <span class="h-8 w-8 rounded-full bg-gray-300 animate-pulse"></span>
                            <!-- <div class="flex flex-col">
                                <p class="bg-gray-300 w-32 h-3 rounded mr-2 animate-pulse mb-2"></p>
                                <p class="bg-gray-300 w-16 h-3 rounded mr-2 animate-pulse"></p>
                            </div> -->
                        </div>
                        <div v-else @click="openCompany = true" class="flex items-center space-x-2 cursor-pointer">
                            <img v-if="companySelected.length > 0 && companySelected[0].company && companySelected[0].company.icon" class="h-8 w-8 rounded-full" :src="companySelected[0].company.icon" alt="">
                            <span v-else class="h-8 w-8 rounded-full bg-theme-gray"></span>
                            <!-- <div class="flex flex-col">
                                <span class="text-xs">{{ GET_USER_INFORMATION[0].company.company_name }}</span>
                                <span class="text-xs text-theme-gray">{{ GET_USER_INFORMATION[0].role }}</span>
                            </div> -->
                        </div>
                        <div v-if="openCompany" class="absolute bg-white rounded mt-9 w-40 z-20 border shadow-lg right-4 top-6 mr-0">
                            <div class="text-xs text-gray-500 uppercase pb-2 mt-4 px-3">{{ $t('company')}}</div>
                            <ul>
                                <li :title="GET_CHOOSEN_COMPANY !== company.uuid ? 'Switch Company' : ''" class="border-b border-t border-gray-100" v-for="company in GET_LOGIN_USER.companies" :key="company.uuid">
                                    <a @click="switchCompanyInfo(company)" href="#" class="block px-4">
                                        <span class="block text-sm mb-2">
                                            <span class="text-gray-800 text-xs">{{ company.company_name }}</span><br>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                            <!-- <div @click="openDrawer = true" class="text-center bg-gray-100 p-2 text-sm">View More</div> -->
                        </div>
                    </div>
                    <div>
                        <div v-if="loading" class="flex items-center space-x-2 cursor-pointer">
                            <div class="flex flex-col">
                                <p class="bg-gray-300 w-32 h-3 rounded mr-2 animate-pulse mb-2"></p>
                                <p class="bg-gray-300 w-16 h-3 rounded mr-2 animate-pulse"></p>
                            </div>
                        </div>
                        <div v-else @click="openSettings = true" class="flex items-center space-x-2 cursor-pointer md:mr-6">
                            <!-- <img v-if="GET_USER_INFORMATION[0].company && GET_USER_INFORMATION[0].company.icon" class="h-8 w-8 rounded-full" :src="GET_USER_INFORMATION[0].company.icon" alt=""> -->
                            <!-- <span v-else class="h-8 w-8 rounded-full bg-theme-gray"></span> -->
                            <div class="flex flex-col">
                                <span class="text-xs" v-if="GET_LOGIN_USER.first_name">{{ $services.helpers.capitalizeEachString(GET_LOGIN_USER.first_name + ' ' + GET_LOGIN_USER.last_name) }}</span>
                                <span class="text-xs text-theme-gray" v-if="GET_USER_INFORMATION.length > 0">{{ GET_USER_INFORMATION[0].role }}</span>
                            </div>
                        </div>
                        <div v-if="openSettings" class="absolute bg-white rounded mt-9 w-40 z-20 border shadow-lg right-4 top-6 mr-0">
                            <div class="text-xs text-gray-500 uppercase pb-2 mt-4 px-3">{{ $t('account')}}</div>
                            <ul>
                                <li class="border-b border-t border-gray-100">
                                    <router-link :to="{ name : 'accounts' }" href="#" class="block px-4">
                                        <span class="block text-sm mb-2">
                                            <span class="text-gray-800 text-xs">{{ $t('viewProfile') }}</span><br>
                                        </span>
                                    </router-link>
                                </li>
                                <li class="border-b border-gray-100">
                                    <a href="javascript:void(0);" @click="logoutUser" class="block px-4">
                                        <span class="block text-sm mb-2">
                                            <span class="text-gray-800 text-xs">{{ $t('logout') }}</span><br>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                            <!-- <div @click="openDrawer = true" class="text-center bg-gray-100 p-2 text-sm">View More</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Bus from './../../../bus'

    export default {
        name : 'NavbarComponent',
        data () {
            return {
                openSettings : false,
                openCompany : false,
                openSwitchTranslation : false,
                loading : true,
                companySelected : []
            }
        },
        watch : {
            GET_CHOOSEN_COMPANY : function (n, o) {
                if (n) {
                    if (this.GET_USER_INFORMATION.length > 0) {
                        this.companySelected = this.GET_USER_INFORMATION.filter(el => el.company.uuid === n)
                    }
                }
            },
            GET_USER_INFORMATION : function (n, o) {
                if (n) {
                    if (this.GET_USER_INFORMATION.length > 0) {
                        this.companySelected = this.GET_USER_INFORMATION.filter(el => el.company.uuid === this.GET_CHOOSEN_COMPANY)
                    }
                }
            },
        },
        computed : {
            ...mapGetters({
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_REF : 'auth/GET_USER_REF',
                GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
                GET_LOCALE : 'locale/GET_LOCALE',
                GET_LOGIN_USER : 'jitcontrol/GET_LOGIN_USER',
                GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY'
            })
        },
        mounted () {
            this.getUser()
            this.getUserInformation()
        },
        methods : {
            getUser () {
                if (this.GET_USER_REF) {
                    this.loading = true
                    if (this.GET_CHOOSEN_COMPANY) {
                        this.$store.dispatch('jitcontrol/getLoginUserInfo', this.GET_USER_REF)
                        .then(res => { 
                            this.loading = false
                            this.filterCompanyData()
                        })
                        .catch(err => {
                            this.loading = false
                            if (err.response.status === 401) {
                                this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                                .then(_ =>{
                                    this.getUser()
                                })
                                .catch(_ => {
                                    this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                    Bus.emit('sign-out')
                                })
                            }
                        })
                    } else {
                        this.$store.dispatch('jitcontrol/getLoginUserInfo', this.GET_USER_REF)
                        .then(res => { 
                            this.loading = false
                            this.filterCompanyData()
                        })
                        .catch(err => {
                            this.loading = false
                            if (err.response.status === 401) {
                                this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                                .then(_ =>{
                                    this.getUser()
                                })
                                .catch(_ => {
                                    this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                    Bus.emit('sign-out')
                                })
                            }
                        })
                    }
                }
            },
            toggleSidebar () {
                Bus.emit('toggle-sidebar')
            },
            getUserInformation () {
                this.loading = true
                this.$store.dispatch('jitcontrol/getUserInfo')
                .then(_ => { this.loading = false })
                .catch(err => {
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getUserInformation()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            },
            changeLocale (locale) {
                this.$i18n.locale = locale
                this.$store.commit('locale/UPDATE_LOCALE', locale)
            },
            removeMenu () {
                this.openSettings = false
                this.openSwitchTranslation = false
                this.openCompany = false
            },
            switchCompanyInfo (company) {
                if (this.GET_CHOOSEN_COMPANY !== company.uuid) {
                    // Update Choosen company and switch
                    this.$store.commit('UPDATE_CHOOSEN_COMPANY', company.uuid)
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                }
            },
            filterCompanyData () {
                if (this.GET_USER_INFORMATION.length > 0) {
                    this.companySelected = this.GET_USER_INFORMATION.filter(el => el.company.uuid === this.GET_CHOOSEN_COMPANY)
                }
            },
            // logoutUser () {
            //     this.$store.commit('auth/CLEAR_AUTH_USER', null)
            //     // this.$router.replace({ name : 'login' })
            //     window.location.href = '/'
            // },
            logoutUser () {
                const payload = {
                    URL : this.$services.endpoints.USER_ENDPOINT + 'logout_all',
                    token : this.USER_REFRESH
                }
                this.$store.dispatch('jitcontrol/customPostRequest', payload)
                .then(res => {
                    this.$store.commit('auth/CLEAR_AUTH_USER', null)
                    Bus.emit('sign-out')
                })
                .catch(err => {
                    Bus.emit('sign-out')
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>