import endpoints from '../../../../services/endpoints'
import { AUTH } from '../../../../services/axios-config'
import TYPES from '../types'

export default {
    async loginUser ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            AUTH.post(endpoints.USER_ENDPOINT + 'login', payload)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_USER_TOKEN, res.data)
                    resolve(res)
                }
            }).catch((err) => {
               //  console.log('catch them here')
                reject(err)
            })
        })
    },
    async refreshToken ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            AUTH.post(endpoints.USER_ENDPOINT + 'token/refresh', payload)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.REUPDATE_USER_TOKEN, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async registerUser ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            AUTH.post(endpoints.USER_ENDPOINT + 'register', payload)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async forgetPassword ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            AUTH.post(endpoints.FORGET_USER_PASSWORD, payload)
            .then(res => {
                resolve(res)
                // if (res.status === 204) {
                // }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async resetPassword ({ _ }, payload) {
        return new Promise((resolve, reject) => {
            AUTH.defaults.headers.common.Authorization = `Token ${payload.token}`
            AUTH.post(endpoints.RESET_USER_PASSWORD, payload)
            .then(res => {
                if (res.status === 200) {
                    AUTH.defaults.headers.common.Authorization = null
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async proceedAPIInvite ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            AUTH.defaults.headers.common.Authorization = `Token ${payload.token}`
            AUTH.post(endpoints.ACCEPT_INVITE_API, payload)
            .then(res => {
                AUTH.defaults.headers.common.Authorization = null
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}