const JITCONTOL_TOKEN = '__jit_services__'
const JITCONTOL_REFRESH = '__jit_dead__'
const JITCONTOL_USER_REF = '__ref_id__'
const JITCONTOL_CHOOSEN_COMPANY = '__company_ref__'

export const saveToken = data => {
    if (data.access) {
        window.localStorage.setItem(JITCONTOL_TOKEN, data.access)
        window.localStorage.setItem(JITCONTOL_REFRESH, data.refresh)
    }
    if (data.id) {
        window.localStorage.setItem(JITCONTOL_USER_REF, data.id)
    }
}

export const saveChoosenCompany = (data) => {
    return window.localStorage.setItem(JITCONTOL_CHOOSEN_COMPANY, data)
}

export const getToken = () => {
    return window.localStorage.getItem(JITCONTOL_TOKEN)
}

export const getRefreshToken = () => {
    return window.localStorage.getItem(JITCONTOL_REFRESH)
}

export const getUserRef = () => {
    return window.localStorage.getItem(JITCONTOL_USER_REF)
}

export const getChoosenCompany = () => {
    return window.localStorage.getItem(JITCONTOL_CHOOSEN_COMPANY)
}


export const destroyToken = () => {
    window.localStorage.removeItem(JITCONTOL_TOKEN)
    window.localStorage.removeItem(JITCONTOL_REFRESH)
    window.localStorage.removeItem(JITCONTOL_USER_REF)
    window.localStorage.removeItem(JITCONTOL_CHOOSEN_COMPANY)
}

export default { saveToken, getToken, getRefreshToken, getUserRef, saveChoosenCompany, getChoosenCompany, destroyToken }
