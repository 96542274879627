import { createWebHistory, createRouter } from 'vue-router'
import AppRoutes from './routes'
import store from './../store'

let routes = []
routes = routes.concat(AppRoutes)

const router = createRouter({
    history : createWebHistory(),
    routes
})

const auth = {
    loggedIn () {
        return store.getters['auth/USER_TOKEN']
    }
}

router.beforeEach((to, from, next) => {
    window.document.title = to.meta && to.meta.title ? to.meta.title : 'Jitcontrol'

    if (to.matched.some(record => record.meta.requireAuth)) { 
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (auth.loggedIn() === null || auth.loggedIn() === undefined) {
            next({
                name : 'signin'
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
    // next() Vue 3 complaining extra next()
})

export default router