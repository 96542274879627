import axios from 'axios'
import { getToken } from './../services-jwt'


export const HTTP = axios.create({
    baseURL : process.env.VUE_APP_BASE_URL_EXTERNAL,
    headers : {
        'Content-Type' : 'application/json',
        Accept : 'application/json'
    }
})

export const AUTH = axios.create({
    baseURL : process.env.VUE_APP_BASE_URL_EXTERNAL,
    headers : {
        'Content-Type' : 'application/json',
        Accept : 'application/json'
    }
})

HTTP.interceptors.request.use(    
    function (req) {
        const token = getToken()
        if (token) { req.headers.common.Authorization = `Token ${token}` }
        return req
    },
    function (err) {
        return Promise.reject(err)
    }
)

AUTH.interceptors.request.use(    
    function (req) {
        return req
    },
    function (err) {
        return Promise.reject(err)
    }
)

export default { HTTP, AUTH }
