import Main from '@/views/Jitcontrol/Main'

const routes = [
    {
        path: '',
        name: 'signin',
        component: () => import(/* webpackChunkName: "chunk" */ '@/views/Auth/Login'),
        meta: {
            title: 'Login',
            page: 'Jitcontrol',
        },
    },
    {
        path: '/register',
        name: 'signup',
        component: () => import(/* webpackChunkName: "chunk" */ '@/views/Auth/Register'),
        meta: {
            title: 'Registration',
            page: 'Jitcontrol',
        },
    },
    {
        path: '/forget-password',
        name: 'forget',
        component: () => import(/* webpackChunkName: "chunk" */ '@/views/Auth/ForgetPassword'),
        meta: {
            title: 'Forget Password',
            page: 'Jitcontrol',
        },
    },
    {
        path: '/password-reset',
        name: 'reset',
        component: () => import(/* webpackChunkName: "chunk" */ '@/views/Auth/ResetPassword'),
        meta: {
            title: 'Reset Password',
            page: 'Jitcontrol',
        },
    },
    {
        path: '/accept_invite',
        name: 'invite',
        component: () => import(/* webpackChunkName: "chunk" */ '@/views/Auth/Accept'),
        meta: {
            title: 'Accept Invitation',
            page: 'Jitcontrol',
        },
    },
    {
        path: '/main',
        meta: { requireAuth: true },
        component: Main,
        children: [
            {
                path: '',
                name: 'dashboard',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/Dashboard')
            },
            {
                path: 'price-rule',
                name: 'price-rule',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/PriceRule')
            },
            {
                path: 'price-management',
                name: 'price-management',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/PriceManage')
            },
            {
                path: 'webshop',
                name: 'webshop',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/Webshop')
            },
            {
                path: 'add-webshop',
                name: 'add-webshop',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/AddWebshop')
            },
            {
                path: 'update-webshop/:id',
                name: 'update-webshop',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/UpdateWebshop')
            },
            {
                path: 'webshop/:uuid',
                name: 'webshop-single',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/SingleWebshop')
            },
            {
                path: 'warehouse',
                name: 'warehouse',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/Warehouse')
            },
            {
                path: 'suppliers',
                name: 'suppliers',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/Supplier')
            },
            {
                path: 'webshop-orders',
                name: 'webshop-orders',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/WebshopOrders')
            },
            {
                path: 'supplier-orders',
                name: 'supplier-orders',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/SupplierOrders')
            },
            {
                path: 'accounts',
                name: 'accounts',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/Accounts')
            },
            {
                path: 'users',
                name: 'users',
                meta: { },
                component: () => import(/* webpackChunkName: "chunk" */ '@/views/Jitcontrol/Users')
            },
        ]
    },
    {
        path : '/:pathMatch(.*)*', 
        redirect : to => {
            return { name : 'notfound' }
        }
    },
]

export default routes
