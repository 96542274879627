import { destroyToken, saveToken } from '../../../../services/services-jwt'

export default {
    UPDATE_USER_TOKEN (state, payload) {
        state.token = payload.access
        state.refresh = payload.refresh
        state.userRef = payload.id
        saveToken(payload)
    },
    CLEAR_AUTH_USER (state, payload) {
        state.token = payload
        state.refresh = payload
        destroyToken()
    },
    REUPDATE_USER_TOKEN (state, payload) {
        state.token = payload.token
        state.refresh = payload.refresh
        const data = {
            access : payload.token,
            refresh : payload.refresh
        }
        saveToken(data)
    }
}
