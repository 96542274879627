import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import VueApexCharts from 'vue3-apexcharts'
import axios from 'axios'
import router from './router'
import services from './services'
import store from './store'
import i18n from './i18n'
import App from './App.vue'
import './assets/css/style.css'

createApp(App)
    .use(i18n)
    .use(router)
    .use(store)
    .use(VueAxios, axios)
    .use(VueApexCharts)
    .use(services)
    .mount('#app')
