import { getPriceRule } from './../../../../services/storage-window'
import { getChoosenCompany } from './../../../../services/services-jwt/'

export default {
    loginUser : {},
    choosenCompany : getChoosenCompany() || null,
    userInfo : {},
    warehouses : [],
    linkedUsers : [],
    webshop : [],
    singleWebshop : {},
    webshopOrders : {
        results : []
    },
    copyWebshopOrders : [],
    webshopProducts : {
        results : []
    },
    copyWebshopProducts : [],
    copyWebshopProductsSync : [],
    supplierOrders : {
        results : []
    },
    copySupplierOrders : [],
    priceList : [],
    priceRule : getPriceRule() || {},
    suppliers : [],
    popularItems : []
}
