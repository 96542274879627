<template>
    <div class="flex h-screen overflow-hidden relative">
        <SidebarComponent />
        <div class="flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
            <NavbarComponent />
            <router-view></router-view>
        </div>
    </div>
    <NotificationHandler />
</template>

<script> 
    import NavbarComponent from './components/Navbar'
    import SidebarComponent from './components/Sidebar'
    import NotificationHandler from '@/components/NotificationHandler'
    import Bus from './../../bus'


    export default {
        name : 'root-main',
        components : { NavbarComponent, SidebarComponent, NotificationHandler },
        data () {
            return {

            }
        },
        mounted () {
            // const { id } = this.$router.params
            Bus.on('sign-out', _ => {
                if (this.$route.path !== '/login') {
                    this.$router.replace({ name : 'signin' })
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
.bg-theme {
    background: #F3F7FC;
}
</style>