import Bus from './../../bus'

export default {
    notification (message, status, _) {
        if (status === 'success') { 
            Bus.emit('notify', {
                message,
                status : 'bg-green-500 text-white',
                mode : 'Success'
            })
        } else if (status === 'error') {
            Bus.emit('notify', {
                message,
                status : 'bg-red-400 text-white',
                mode : 'Error'
            })
        }
    },
    validateEmail :  function (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    capitalizeEachString (name) {
        return name !== undefined && name !== null ? name.split(' ').map(v => typeof v !== 'string' ? v + ' ' : v.charAt(0).toUpperCase() + v.toLowerCase().slice(1) + ' ').join('').trim() : ''
    },
    capitalizeNames (name) {
        return name !== null ? name.charAt(0).toUpperCase() + name.toLowerCase().slice(1) : name
    },
    validateCompanyNumber (number) {
        if (!number.includes('-')) {
            return true
        }
        if (number.split('-')[0].length === 6 && number.split('-')[1].length === 4) {
            return false
        }
        return true
    },
    initialNames (name) {
        return name !== undefined ? name.charAt(0).toUpperCase() : name
    },
    shortedDescriptionText (str, n){
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    }
}