const LOCALE = '__xlocale__'
const PRICE_RULE = '__ruleSettings__'

export const saveLocale = (payload) => {
    window.localStorage.setItem(LOCALE, payload)
}

export const savePriceRule = (payload) => {
    const storage = JSON.parse(window.localStorage.getItem(PRICE_RULE))
    if (storage) {
        const rule = storage
        if (payload.margin) {
            const obj = { ...rule, margin : payload.margin }
            window.localStorage.setItem(PRICE_RULE, JSON.stringify(obj))
        } else if (payload.vat) {
            const obj = { ...rule, vat : payload.vat }
            window.localStorage.setItem(PRICE_RULE, JSON.stringify(obj))
        } else if (payload.round) {
            const obj = { ...rule, round : payload.round }
            window.localStorage.setItem(PRICE_RULE, JSON.stringify(obj))
        }
    } else {
        window.localStorage.setItem(PRICE_RULE, JSON.stringify(payload))
    }
}

export const getPriceRule = function () {
    return JSON.parse(window.localStorage.getItem(PRICE_RULE))
}

export const getLocale = () => {
    return window.localStorage.getItem(LOCALE)
}

export default { saveLocale, getLocale, savePriceRule, getPriceRule }
