<template>
    <div class="fixed top-0 right-0 m-6 index-z">
        <Transition name="slide-fade">
            <div v-if="messageObject.message">
                <div :class="messageObject.status" class="bg-white rounded-lg shadow-md py-3 pl-3 pr-1" style="min-width: 280px">
                    <div class="flex justify-between mb-7">
                        <div class="absolute top-0 left-0 py-2 px-3">{{ messageObject.mode }}</div>
                        <button class="opacity-75 cursor-pointer absolute top-0 right-0 py-2 px-3 hover:opacity-100">
                            x
                        </button>
                    </div>
                    <div class="flex whitespace-pre-line items-center font-semibold text-xs w-80">
                        {{ messageObject.message }}
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
    import Bus from './../bus'

    export default {
        name : 'NotificationHandler',
        data () {
            return {
                messageObject : {
                    status : null,
                    message : null,
                    mode : null
                }
            }
        },
        mounted () {
            let timer
            Bus.on('notify', (data) => {
                clearTimeout(timer)
                
                this.messageObject = data
                
                timer = setTimeout(() => {
                    this.messageObject.message = null
                }, 3000)
            })
        },
    }
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(400px);
  opacity: 0;
}
.index-z{
    z-index: 99999999;
}
</style>
